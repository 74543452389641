import { GroupBase, MultiValueRemoveProps, components } from 'react-select';

import { OptionType } from './Select.types';

export function MultiValueRemove<
  Option = OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  innerProps,
  ...otherProps
}: MultiValueRemoveProps<Option, IsMulti, Group>) {
  return (
    <components.MultiValueRemove
      innerProps={innerProps}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  );
}
