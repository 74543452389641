import { ClearIndicatorProps, GroupBase, components } from 'react-select';

import { Icon } from 'components/Icon/Icon';

import { OptionType } from './Select.types';

export function ClearIndicator<
  Option = OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: ClearIndicatorProps<Option, IsMulti, Group>) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ClearIndicator {...props}>
      <Icon name="close" size={12} />
    </components.ClearIndicator>
  );
}
