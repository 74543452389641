import { LISTING_TYPE_PLURAL_HUMAN_NAME_MAP } from './listingTypePluralHumanNameMap';

export const OBJECT_TYPE_PLURAL_HUMAN_NAME_MAP = {
  ...LISTING_TYPE_PLURAL_HUMAN_NAME_MAP,
  ARTICLE: getText('articles'),
  CANDIDATE: getText('candidates'),
  USER: getText('users'),
  INVOICE: getText('invoices'),
  PAYMENT: getText('payments'),
  DONATION: getText('donations'),
};
