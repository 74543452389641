import { DropdownIndicatorProps, GroupBase, components } from 'react-select';

import { Icon } from 'components/Icon/Icon';

import { OptionType } from './Select.types';

export function DropdownIndicator<
  Option = OptionType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: DropdownIndicatorProps<Option, IsMulti, Group>) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <Icon name="chevron-down" size={16} />
    </components.DropdownIndicator>
  );
}
