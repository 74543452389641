import { GroupBase, StylesConfig } from 'react-select';
import styled from 'styled-components';

import { SelectInput } from 'components/SelectInput';
import { colors, transition } from 'theme/theme';

export const StyledSelectInput = styled(SelectInput)`
  height: 50px;
  padding: 0 12px;
  color: ${colors.brandBlue};
`;

type Args = {
  height?: number;
};

export function selectStyles<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ height }: Args): StylesConfig<Option, IsMulti, Group> {
  return {
    control: (base, state) => ({
      ...base,
      borderRadius: '8px',
      backgroundColor: 'white',
      width: '100%',
      minHeight: '45px',
      height,
      margin: '0',
      cursor: 'pointer',
      borderColor: state.isFocused ? colors.brandBlue : colors.elementGrey,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.selectProps.menuIsOpen
        ? colors.brandBlue
        : colors.lightContentGrey,
      paddingLeft: state.selectProps.menuIsOpen ? '19px' : undefined,
      paddingRight: state.selectProps.menuIsOpen ? undefined : '19px',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : undefined,
    }),
    input: (base) => ({
      ...base,
      height: '100%',
      paddingLeft: '0',
    }),
    valueContainer: (base, state) => ({
      ...base,
      display: 'flex',
      padding: state.hasValue && state.isMulti ? '2px 12px' : '0 12px',
      alignItems: 'center',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      paddingTop: '2px',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    singleValue: (base) => ({
      ...base,
      color: colors.mediumContentGrey,
      fontSize: '18px',
      margin: '0',
      maxWidth: 'calc(100% - 15px)',
    }),
    multiValue: (base) => ({
      ...base,
      background: colors.inputGrey,
      color: colors.brandBlue,
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: 'inherit',
    }),
    multiValueRemove: (base) => ({
      ...base,
      borderRadius: '0 2px 2px 0',
      transition: `all ${transition}`,
      ':hover': {
        background: colors.brandBlue,
        color: 'white',
      },
    }),
    menu: (base) => ({
      ...base,
      marginTop: '1px',
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.15)',
      border: `solid 1px ${colors.elementGrey}`,
    }),
    menuList: (base) => ({
      ...base,
      padding: '0',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 10_000_1,
    }),
    option: (base, state) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      borderBottom: `solid 1px ${colors.elementGrey}`,
      color: colors.mediumContentGrey,
      padding: '0 12px',
      minHeight: '45px',
      cursor: 'pointer',
      backgroundColor:
        state.isFocused || state.isSelected ? 'rgba(241, 241, 241, 1)' : 'none',
    }),
  };
}

export function multiSelectStyles<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(): StylesConfig<Option, IsMulti, Group> {
  return {
    container: (base) => ({
      ...base,
      display: 'block',
      width: '100%',
      minHeight: '45px',
      verticalAlign: 'middle',
      color: colors.mediumContentGrey,
      fontSize: '18px',
    }),
    control: (base) => ({
      ...base,
      borderRadius: '8px',
      backgroundColor: 'white',
      width: '100%',
      minHeight: '45px',
      margin: '0',
      cursor: 'pointer',
    }),
    input: (base) => ({
      ...base,
      height: '100%',
      paddingLeft: '0',
    }),
    valueContainer: (base, state) => ({
      ...base,
      display: 'flex',
      padding: state.hasValue && state.isMulti ? '2px 12px' : '0 12px',
      alignItems: 'center',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      paddingTop: '2px',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    multiValue: (base) => ({
      ...base,
      background: colors.inputGrey,
      color: colors.brandBlue,
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: 'inherit',
    }),
    multiValueRemove: (base) => ({
      ...base,
      borderRadius: '0 2px 2px 0',
      transition: `all ${transition}`,
      ':hover': {
        background: colors.brandBlue,
        color: 'white',
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: '5',
      marginTop: '1px',
      borderRadius: '0 0 8px 8px',
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.15)',
      border: `solid 1px ${colors.elementGrey}`,
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 10_000_1,
    }),

    menuList: (base) => ({
      ...base,
      padding: '0',
    }),
    option: (base, state) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      borderBottom: `solid 1px ${colors.elementGrey}`,
      color: state.isDisabled ? '#999999' : colors.mediumContentGrey,
      padding: '0 12px',
      minHeight: '45px',
      cursor: 'pointer',
      fart: 'boy',
      backgroundColor:
        !state.isDisabled && (state.isFocused || state.isSelected)
          ? 'rgba(241, 241, 241, 1)'
          : 'none',
    }),
  };
}
